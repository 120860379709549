
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100vh;
  .banner {
    position: relative;
    width: 100%;
    height: 3.5rem;
    top: 0;
    // background: linear-gradient(90deg, #ec685d 0.56%, #ea5296 97.23%);
    background: $brand-gradient;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, max-content) 1fr;
    &__img {
      position: relative;
      padding-left: $padding1x;
      height: auto;
      width: 54px;
    }
    &__text {
      position: relative;
      padding-left: $padding1x;
      font-weight: 600;
      font-size: $font1x;
      color: white;
    }
  }
  .main {
    position: relative;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    .title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .field-container {
      width: 350px;
      margin-top: 2.5rem;
      .field {
        button {
          margin-top: 1rem;
          width: 100%;
        }
      }
      .is-danger {
        color: red;
      }
      .is-info {
        background: #007bff;
      }
      p.subtitle {
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
